import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
// import { TextPlugin } from "gsap/TextPlugin.js";
// import { SlowMo } from "gsap/EasePack.js";


gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false
})

export const initGsap = (pageName) => {


  const rsContainerSections = gsap.utils.toArray('header, section, footer');
  const rsAnimDelay = 15;

  rsContainerSections.forEach((section) => {
    const rsRevealAnimations = gsap.utils.toArray('.RS-animation', section);
    
    rsRevealAnimations.forEach((item, index) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top bottom',
          end: 'bottom top',
          trigger: item,
          toggleClass: 'RS-animation--enter',
          once: true,
          scrub: 1,
          stagger: 5,
        }
      });
  
      item.style.transitionDelay = `${index * rsAnimDelay}ms`;
    });
  });



  const rsRevealAnimations = gsap.utils.toArray('.RS-animation');
  rsRevealAnimations.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-animation--enter',
        once: true,
        scrub: 1,
        stagger: 5,
      }
    });
  });

  const rsLoopedAnimations = gsap.utils.toArray('.RS-looped-animation');

  rsLoopedAnimations.forEach(item => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-looped-animation--running',
        scrub: 1
      }
    });
  });



  const topNavBar = document.querySelector('.RS-top-bar');
  const topNavBarMobileIcon = document.querySelector('.RS-top-bar__mobile-nav-icon');

  ScrollTrigger.create({
    start: 0,
    end: 99999,
    onUpdate: ({direction}) => {
      if (direction == -1) {
        topNavBar.classList.remove('RS-top-bar--hidden');
      } else {
        if(!topNavBarMobileIcon.classList.contains('RS-top-bar__mobile-nav-icon--active')){
          topNavBar.classList.add('RS-top-bar--hidden');
        }
      }
    }
  });

  

  if(pageName === 'Home'){
    
    /* BG pictures */

    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      start: 'top top',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-background-picture--hero', {yPercent: 0, scale:1, rotateZ: 0, opacity: 1 }, {yPercent: 0, scale: 1.3, rotateZ: 3, opacity: 0.2}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-about-hania',
      start: 'top center',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-background-picture--about-hania', {yPercent: 0, xPercent: 0, scale:1, rotateZ: 0, opacity: 1 }, {yPercent: 0, xPercent: -7.5, scale: 1.2, rotateZ: -3, opacity: 0.2}),
      scrub: 1
    })

        
    ScrollTrigger.create({
      trigger: '#RS-section-about-project',
      start: 'top center',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-background-picture--about-project', {yPercent: 0, xPercent: 0, scale:1, rotateZ: 0, opacity: 1 }, {yPercent: 0, xPercent: 5, scale: 1.2, rotateZ: -3, opacity: 0.2}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-newsletter',
      start: 'top center',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-background-picture--newsletter', {yPercent: 0, scale:.96, rotateZ: 3, opacity: 0.3}, {yPercent: 0, scale: 1, rotateZ: 0, opacity: 1}),
      scrub: 1
    })

    /* BG decorations */

    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      start: 'top top',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-background__hero-decoration-picture--first', {yPercent: 0, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 100, xPercent: 0, scale: .9, opacity: 0.2}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      start: 'top top',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-background__hero-decoration-picture--second', {yPercent: 0, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 135, xPercent: 0, scale: .8, opacity: 0.2}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-about-project',
      start: 'top center',
      end: 'bottom top',
      animation: gsap.fromTo('.RS-background__about-project-decoration-picture', {yPercent: 0, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 135, xPercent: -100, scale: .8, opacity: 0.2}),
      scrub: 1
    })


    /* MISC */

    ScrollTrigger.create({
      trigger: '#RS-video__overlay',
      start: 'top top=-50',
      end: 'bottom center',
      animation: gsap.fromTo('#RS-video__label', {yPercent: -50, opacity: 0}, {yPercent: 0, opacity: 0.67}),
      scrub: 1
    })

    const words = ['samopoczucie', 'odporność', 'stan skóry', 'tarczycę', 'psychikę', 'zdrowie włosów', 'menstruację', 'paznokcie', 'masę ciała', 'układ nerwowy', 'jakość snu', 'poziom energii', 'regenerację'];
    let counter = 0;

    // Create a temporary element to measure text width
    // const tempElement = document.createElement('span');
    // tempElement.id = 'tempElement';
    // tempElement.style.visibility = 'hidden';
    // document.getElementById('RS-header-description-intro').appendChild(tempElement);

    // let maxWidth = 0;

    // words.forEach(word => {
    //   tempElement.textContent = word;
    //   maxWidth = Math.max(maxWidth, tempElement.offsetWidth);
    //   console.log(maxWidth);
    // });

    // Remove the temporary element
    // document.getElementById('tempElement').remove();

    // Set the width of the #demo element
    const textElement = document.getElementById('RS-word-array');
    // textElement.style.width = `${maxWidth}px`;

    let timeoutId;
    
    function animateWord() {

      const videoElement = document.getElementById('RS-video');

      if (!videoElement.paused) {
        clearTimeout(timeoutId);
        return;
      }    

      const word = words[counter];
      textElement.textContent = word;
      
      gsap.fromTo(textElement, { opacity: 0, yPercent: -10, rotationZ:0.01, rotationX: 10, scale: .985, }, { opacity: 1, yPercent:0, rotationZ:0, rotationX: 0, duration: .4, scale: 1, ease: "none" });
      
      counter++;
      
      if (counter >= words.length) {
        counter = 0; // Reset counter to 0 when it reaches the length of the array
      }
      
      timeoutId = setTimeout(animateWord, 3500);
    }
    
    // Create an IntersectionObserver to check if the #demo element is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const videoElement = document.getElementById('RS-video');

        if (entry.isIntersecting && videoElement.paused) {
          // If the #demo element is visible, start the animation
          animateWord();
        } else {
          // If the #demo element is not visible, stop the animation
          clearTimeout(timeoutId);
        }
      });
    });

    // Start observing the #demo element
    observer.observe(textElement);

    const videoElement = document.getElementById('RS-video');
    videoElement.addEventListener('pause', animateWord);

  }


};